<template>
  <!-- 知识管理 -->
  <div>
    <div>
      <repPbcNav
        :iptData="iptData"
        @searchAdd="searchAdd"
        @resetBtn="resetBtn"
      ></repPbcNav>
    </div>
    <div>
      <repForm
        @edit="edit"
        btnTitle="发布新知识"
        @handleAdd="handleAdd"
        v-model:columns="columns"
        :dataList="dataList"
        @swtichBtn="swtichBtn"
        :reload="getRepList"
        :searchLoading="searchLoading"
        :pagination="pagination"
        @changePage="changePage"
        @statistics="statistics"
      ></repForm>
    </div>
    <div>
      <repModal
        :visible="visible"
        @getVal="getVal"
        @addClose="addClose"
        @seleChange1="seleChange1"
        @cancel="cancel"
        @handleChange="handleChange"
        title="添加新知识"
        :fromList="fromList"
      ></repModal>
    </div>
    <div>
      <tabelModal title="查看统计" @resetBtn="statisResetBtn" @searchAdd="tatisticsSearchAdd" @changePage="tatisticsChangePage" :pagination="statisticsPagination" :statisticsColumns="statisticsColumns" :statisticsIpt="statisticsIpt" :statisticsList="statisticsList" v-model:visible="articleVisible"  :articleTitle="articleTitle"></tabelModal>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import repPbcNav from "../components/repPbcNav.vue";
import repForm from "../components/repForm.vue";
import repModal from "../components/repModal.vue";
import tabelModal from '../components/tabelModal.vue'; 
import { IfNull } from '@/utils/util';
import {
  oaTree,
  repContentAdd,
  repContentList, 
  oaHideContent,
  oaFindID,
  oaContentEdit,
  oaContentPage
} from "@/api/repository/repository";
import { getOrgTreeList } from "@/api/global";
import { message } from "ant-design-vue";
const visible = ref(false);
const isEdit = ref(false);
const searchLoading = ref(false);
const page = ref(1)
const tatisticsPage = ref(1)
const articleTitle = ref('')
const pagination = ref( {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (totals) => `共 ${totals} 条`
  })
const statisticsPagination = ref({
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (totals) => `共 ${totals} 条`
  }) 
const statisticsList  = ref([]);//统计展示数据列表
const statisticsColumns = ref([
  {
    title: "查看业务员",
    dataIndex: "operator",
    key: "operator",
    align: "center",
  },
  {
    title: "所属部门",
    dataIndex: "applyOrg",
    key: "applyOrg",
    align: "center",
  },
  {
    title: "查看时间",
    dataIndex: "createTime",
    key: "createTime",
    align: "center",
  },
  {
    title: "是否有用",
    dataIndex: "beed",
    key: "beed",
    align: "center",
  },
]);
const statisticsIpt = ref([
  {
    type: "select",
    placeholder: "全部",
    value: null,
    prop: "department",
    width: 200,
    opt: [{
      id: "",
      name: "全部"
    },
    {
      id: 1,
      name: "有用"
    },
    {
      id: 0,
      name: "无用"
    }
  ],
  },
])

const iptData = ref([
  {
    type: "select",
    placeholder: "适用部门",
    value: null,
    prop: "department",
    width: 200,
    opt: [],
  },
  {
    type: "select",
    placeholder: "是否隐藏",
    value: null,
    prop: "isHide",
    width: 200,
    opt: [
      {
        id: 0,
        name: "是",
      },
      {
        id: 1,
        name: "否",
      },
    ],
  },
  {
    type: "cascader",
    placeholder: "请选择类目",
    value: null,
    prop: "category",
    width: 200,
    opt: [],
  },
  {
    type: "input",
    placeholder: "关键词",
    value: "",
    prop: "keywords",
    width: 200,
  },
  {
    type: "input",
    placeholder: "知识标题",
    value: "",
    prop: "title",
    width: 200,
  },
]);
const columns = ref([
  {
    title: "标题",
    dataIndex: "title",
    key: "title",
    align: "center",
    slots: { customRender: "title" },
  },
  {
    title: "关键词",
    dataIndex: "keywordList",
    key: "keywordList",
    align: "center",
  },
  {
    title: "知识内容",
    dataIndex: "content",
    key: "content",
    width: 300,
    customHeaderCell: () => ({ style: { textAlign: "center" } }),
    slots: { customRender: "content" },
  },
  {
    title: "图片数量",
    key: "picNum",
    dataIndex: "picNum",
    align: "center",
    width: 100,
  },
  {
    title: "所属类目",
    key: "knowledgeType",
    dataIndex: "knowledgeType",
    align: "center",
  },
  {
    title: "适用部门",
    key: "applyOrg",
    dataIndex: "applyOrg",
    align: "center",
  },
  {
    title: "创建人",
    key: "operator",
    dataIndex: "operator",
    align: "center",
    width: 100,
  },
  {
    title: "创建时间",
    key: "createTime",
    dataIndex: "createTime",
    align: "center",
  },
  {
    title: "是否隐藏",
    key: "isShow",
    dataIndex: "isShow",
    slots: { customRender: "isShow" },
    align: "center",
    width: 100,
  },
  {
    title: "统计",
    key: "statistics",
    dataIndex: "statistics",
    align: "center",
    slots: { customRender: "statistics" },
    width: 160,
  },
  {
    title: "操作",
    key: "edit",
    dataIndex: "edit",
    slots: { customRender: "edit" },
    align: "center",
    wdith: 50,
  },
]);
const dataList = ref([]);//展示数据列表
//表单数据
const fromList = ref([
  {
    label: "选择类目",
    name: "client1",
    type: "mulSelect",
    placeholder1: "请选择一级目录",
    placeholder2: "请选择二级目录",
    value1: null,
    value2: null,
    prop1: "client1",
    prop2: "client2",
    opt1: [],
    opt2: [],
    rules1: [{ required: true, message: "Please input Activity name", trigger: "blur" }],
    rules2: [{ required: true, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "适用部门",
    name: "department",
    type: "select",
    placeholder: "请选择",
    value: null,
    prop: "department",
    opt: [],
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "知识标题",
    name: "title",
    type: "input",
    placeholder: "",
    value: null,
    prop: "title",
    width: "100%",
    rules: [{ required: true, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "关键词",
    name: "keywords",
    type: "tag",
    placeholder: "",
    tips: "（多个关键词请用 “、” 隔开）",
    value: null,
    prop: "keywords",
    width: "100%",
    opt: [],
    rules: [{ required: true, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "知识内容",
    name: "reqContent",
    type: "textarea",
    placeholder: "在这里输入关键词，点击右下角发送图标发送内容，唤起知识库相关知识",
    value: null,
    prop: "reqContent",
    rules: [{ required: true, message: "Please input Activity name", trigger: "blur" }],
  },
  {
    label: "上传图片",
    name: "img",
    type: "upload",
    value: [],
    prop: "upload",
    rules: [{ required: false, message: "Please input Activity name", trigger: "blur" }],
  },
]);
//控制分页
const changePage = (val) => {
  page.value = val.current;
  getRepList(recordSearch.value)
  pagination.value = val
}
// 统计筛选搜索事件
const tatisticsSearchAdd = (val) => {
  tatisticsPage.value = 1
  statisticsPagination.value.current = 1
  fondType.value = val.department
  reqOaContentPage()
}
//统计分页
const tatisticsChangePage = (val) => {
  tatisticsPage.value = val.current;
  statisticsPagination.value = val
  reqOaContentPage()
}
//统计弹窗
const articleVisible = ref(false)
const statisticsID = ref(0)
const fondType = ref(null)
 const statistics = (val) => {
  articleTitle.value = val.title
  tatisticsPage.value = 1
  statisticsPagination.value.current = 1
  statisticsID.value = val.id
  reqOaContentPage()
  articleVisible.value = true
 
}
//重置
const resetBtn = () => {
  page.value = 1
  pagination.value.current = 1
  recordSearch.value = {}
  getRepList()
}
//统计重置
const statisResetBtn = () => {
  tatisticsPage.value = 1
  statisticsPagination.value.current = 1
  fondType.value = ''
  reqOaContentPage()
}
//统计请求
const reqOaContentPage = () =>{
  oaContentPage({
    contentId: statisticsID.value,
    current:tatisticsPage.value,
    size:10,
    fondType:fondType.value
  }).then(res => {
    if(res.code !== 10000) return
    res.data.records.forEach(item => {
      if(item.fondType){
        item.beed =item.fondType.label
      }else{
        item.beed = ""
      }
    })
    statisticsPagination.value.total = res.data.total
    statisticsList.value = res.data.records
  })
}
//表格编辑
const enitID = ref('') //编辑id
const edit = (val) => {
  enitID.value = val.id
  oaFindID(val.id).then((res) => {
    isEdit.value = true
    let data = res.data;
    let lev1Content = ""; //一级内容
    let lev2Content = ""; //二级内容
    let children = []; //二级列表
    resData.value.forEach((item) => {
      //表示只有一级
      if (item.value == data.knowledgeTypeId) {
        lev1Content = item.label;
        return;
      }
      //第二级
      item.children.forEach((clid) => {
        if (clid.value == data.knowledgeTypeId) {
          lev1Content = item.label;
          lev2Content = clid.label;
          children = item.children;
        }
      });
    });
   if(data.fileUrls){
    data.fileUrls.map( item => {
      item.uid = item.id
    })
   }else{
    data.fileUrls = []
   }
    fromList.value[0].value1 = lev1Content;
    fromList.value[0].value2 = lev2Content;
    fromList.value[0].opt2 = children;
    fromList.value[1].value = data.applyOrg;
    fromList.value[2].value = data.title;
    fromList.value[3].opt = data.keywordList;
    fromList.value[4].value = data.content;
    fromList.value[5].value = data.fileUrls;
    visible.value = true;
  });
};
//关闭弹出层
const cancel = () => {
  visible.value = false;
};

//头部搜索框
const recordSearch = ref({})
const searchAdd = (val) => {
  let msg = {
    applyOrgId:val.department,//部门
    isEnable:val.isHide,//是否隐藏
    knowledgeTypeId:val.category&&val.category.length==2?val.category[1]:'',//二级类目id
    keyword:val.keywords,
    title:val.title
  }
  recordSearch.value = msg
  page.value = 1;
  pagination.value.current = 1
  getRepList(msg)
};

// 重置表单信息
const resetForm = () => {
    fromList.value[0].value1 = null;
    fromList.value[0].value2 = null;
    fromList.value[0].opt2 =[];
    fromList.value[1].value = null;
    fromList.value[2].value =null;
    fromList.value[3].opt =[];
    fromList.value[4].value = null;
    fromList.value[5].value = [];
}
//添加
const handleAdd = () => {
  resetForm()
  isEdit.value = false
  visible.value = true;
};
//关键词获取
const handleChange = (item, val) => {
  if(!IfNull(val)) return
  if(IfNull(val).length<2){
    message.warn('关键字最低2个字')
    return
  }
  if(IfNull(val).length>5){
    message.warn('关键字在5字以内')
    return
  }
  if(item.opt.includes(IfNull(val))){
    message.warn('关键字已存在')
    return
  }
  item.opt.push(IfNull(val));
};
//删除关键词
const addClose = (item, idx) => {
  item.opt.splice(idx, 1);
};
//知识内容确认添加
const knowledgeTypeId = ref("");
const knowledgeType = ref("");
const applyOrgId = ref("");
const fileIds = ref([]);//图片id
const getVal = (val,fileList) => {
  resData.value.forEach((item) => {
    if (val.client2) {
      item.children.forEach((opt) => {
        if (opt.label == val.client2) {
          knowledgeType.value = opt.label;
          knowledgeTypeId.value = opt.value;
        }
      });
      return;
    }
    if (item.label == val.client1) {
      knowledgeType.value = item.label;
      knowledgeTypeId.value = item.value;
    }
  });
  fromList.value[1].opt.forEach((opt) => {
    if (opt.name == val.department) {
      applyOrgId.value = opt.id;
    }
  }); 
    let  idx = []
   if(fileList.fileIds.length>0){
    fileList.fileIds.forEach((fileId) =>{
      idx.push(fileId.id)
      fileIds.value = idx
    })
   }else{
    fileIds.value = idx
   }
  let msg = {
    applyOrgId:applyOrgId.value, //部门id
    applyOrg: val.department,//部门
    content: val.reqContent, //内容
    keywordList: fromList.value[3].opt, //关键词
    knowledgeType: knowledgeType.value, //类别
    knowledgeTypeId: knowledgeTypeId.value, //类别id
    title: val.title, //标题
    fileIds:fileIds.value
  };
   if(formRules(msg)!== true){
     message.warning(formRules(msg));
     return;
   }
  if(isEdit.value){
    reqEdit(msg)
  }else{
    reqNewAdd(msg)
  }
};
//表单验证
const formRules = (msg) => {
  if(!msg.knowledgeType){
    return "请选择类目"
  }else if(!msg.title){
    return '请输入类目标题'
  }else if(msg.keywordList.length == 0){
    return '请选择关键词'
  }else if(!msg.content){
    return '请输入知识内容'
  }else{
    return true
  }
}
//新增请求
const reqNewAdd = (msg) => {
  repContentAdd(msg)
    .then((res) => {
      if (res.code == 10000) {
        message.success(res.msg);
        getRepList();
        cancel()
      }
    })
    .catch((err) => {
      message.success(res.msg);
    });
}
// 编辑请求
const reqEdit = (msg) => {
   msg.id = enitID.value
  oaContentEdit(msg).then(res => {
    if (res.code == 10000) {
        message.success(res.msg);
        getRepList();
        cancel()
      }
  }).catch((err) => {
      message.success(res.msg);
    });
  }
//是否隐藏
const swtichBtn = (item) => {
  oaHideContent({
    id: item.id,
    isEnable: {
      label: "",
      value: item.isEnable.value == 0 ? 1 : 0,
    },
  }).then((res) => {
    if (res.code == 10000) {
      message.success(res.msg);
      if (item.isEnable.value == 1) {
        item.isEnable.value = 0;
      } else {
        item.isEnable.value = 1;
      }
    }
  });
};
//根据一级获取二级
const seleChange1 = (val) => {
  resData.value.forEach((item) => {
    if (item.label == val) {
      fromList.value[0].opt2 = item.children;
    }
  });
};

// 获取类目列表
const resData = ref([]);
const grtOaList = () => {
  oaTree().then((res) => {
    if (res.code !== 10000) return;
    fromList.value[0].opt1 = res.data;
    iptData.value[2].opt = res.data
    resData.value = res.data;
  });
};
//获取部门
const getDepartment = () => {
  getOrgTreeList({}).then((res) => {
    if (res.code !== 10000) return;
    let treeList = res.data[0].treeList;
    let arr = {
      id:0,
      name:'全部部门'
    }
    treeList.unshift(arr)
    fromList.value[1].opt = treeList;
    iptData.value[0].opt = treeList;
  });
};

//获取知识列表
const getRepList = (msg={}) => {
  searchLoading.value = true
  let primary = {
    orders: [
      {
        asc: false,
        column: "",
      },
    ],
    current: page.value,
    size: 10,
  }
    Object.assign(primary,msg)
  repContentList(primary).then((res) => {
    res.data.records.forEach((item) => {
      item.edit1 = "编辑";
      item.statistics = `总数（${item.total}）有用（${item.usefulNum}）无用（${item.notUsefulNum}）`;
    });
    pagination.value.total = res.data.total
    dataList.value = res.data.records;
    searchLoading.value = false
  });
};
onMounted(() => {
  grtOaList();
  getDepartment();
  getRepList();
});
</script>

<style lang="less" scoped>

</style>
