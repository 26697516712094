<template>
  <div>
    <a-modal :width="width" :visible="visible" :title="title" :footer="null" @cancel="cancel">
       <div class="title">
        {{ articleTitle }}  
       </div>
      <div>
        <repPbcNav @searchAdd="searchAdd" @resetBtn="resetBtn" :iptData="statisticsIpt"></repPbcNav>
      </div>
      <div style="margin-top: -50px;">
        <repForm :dataList="statisticsList" size="small" :pagination="pagination"  @changePage="changePage" :isNavOperation="false" :columns="statisticsColumns"></repForm>
      </div>
    </a-modal>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import repPbcNav from './repPbcNav.vue'
import repForm from './repForm.vue'
const props = defineProps({
  title:{
    type: String,
    default:'标题'
  },
  articleTitle:{
    type: String,
    default:''
  },
  width: {
    type: String,
    default: '40%'
  },
  visible:{
    type: Boolean,
    default: false
  },
  statisticsColumns:{
    type: Array,
    default: []
  },
  statisticsIpt:{
    type: Array,
    default: []
  },
  statisticsList:{
    type: Array,
    default: []
  },
  pagination:{
  type: Object
  }
})
const emit = defineEmits(['update:visible','changePage','searchAdd','resetBtn'])
const cancel = () => {
  emit('update:visible', false)
}
const changePage = (val) => {
  emit('changePage',val)
}
const searchAdd = (val) =>{
  emit('searchAdd',val)
}
//重置
const resetBtn = () => {
  emit('resetBtn')
}
</script>

<style lang="less" scoped>
.title{
    background-color: rgba(244, 245, 249, 1);
    padding: 8px 12px;
    border-radius: 6px;
    margin-top: -10px;
}
</style>